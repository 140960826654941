import {useTranslation} from '../../tools/i18n';
import theme from "../theme";
import React, {useState} from "react";
import Select, {components} from 'react-select';
import DropdownIndicatorOpen from "../../public/images/icons/minus.svg";
import DropdownIndicatorClosed from "../../public/images/icons/plus.svg";
import {makeStyles} from "@material-ui/styles";
import Link from "next/link";
import DropdownIndicator from "../../public/images/icons/drop-down.svg";

const useStyles = makeStyles({
	control: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: 15,
		padding: '10px 12px 10px 12px',
		borderRadius: 0,
		backgroundColor: '#f5f5f5',
		cursor: 'pointer',
		'& svg': {
			'&.desktop': {
				[theme.breakpoints.down('lg')]: {
					display: 'none'
				}
			},
			'&.mobile': {
				[theme.breakpoints.up('lg')]: {
					display: 'none'
				}
			}
		},
		[theme.breakpoints.down('md')]: {
			marginTop: -1,
			marginBottom: 0,
			padding: '25px 15px 25px 15px',
			backgroundColor: '#fff',
			borderTop: '1px solid #e0e0e0',
			borderBottom: '1px solid #e0e0e0',
			'&.open': {
				marginBottom: 15,
				paddingBottom: 0,
				borderBottom: 'none'
			}
		}
	},
	subCategoriesList: {
		display: 'none',
		paddingLeft: 20,
		'&.open': {
			display: 'flex',
			flexDirection: 'column'
		}
	},
	link: {
		color: 'inherit',
		textDecoration: 'none'
	},
	label: {
		display: 'flex',
		alignItems: 'center',
		height: 36,
		padding: '2px 15px 2px 41px',
		'& svg': {
			width: 36,
			height: 32,
			padding: '6px 12px',
			marginRight: 5
		},
		'&.hasChildren': {
			paddingLeft: 0
		},
		'&.isSelected': {
			fontWeight: 600
		}
	}
}, {name: 'FilterDropdownLink'});

export default function FilterDropdownLink({name, currentFilter, options, className}) {
	const classes = useStyles();
	const { t } = useTranslation(['general','attributes']);
	const [open, setOpen] = useState(true);
	const [childOpen, setChildOpen] = useState([]);

	function formatLabel(data, hasChildren, isSelected) {
		return <div className={[classes.label, hasChildren ? 'hasChildren' : '', isSelected ? 'isSelected' : ''].join(' ')} onClick={(e) => {
			if(hasChildren) {
				e.preventDefault();
				e.stopPropagation();
				toggleChildOpen(data.value);
			}
		}}>
			{hasChildren ? <DropdownIndicator width={12}/> : ''}
			<label style={{cursor: 'pointer'}}>{data.label}</label>
		</div>;
	}

	function Control({children, ...props}) {
		return '';
	}

	function Option({children, ...props}) {
		if(props.data.value === currentFilter) {
			props.isSelected = true;
		}

		let childCategories = props.data?.children || [];

		return (
			<>
				<Link href={props.value}>
					<a className={classes.link}>
						<components.Option {...props} className={classes.dropdownOption}>
							{formatLabel(props.data, props.data?.children.length > 0 || false, props.isSelected)}
						</components.Option>
					</a>
				</Link>

				{childCategories.length > 0 ?
					<div className={[classes.subCategoriesList, childOpen.indexOf(props.value) >= 0 ? 'open' : ''].join(' ')}>
						<Link href={props.value}>
							<a className={classes.link}>
								<components.Option {...props} className={classes.dropdownOption}>
									{formatLabel({label: t('general:see-all'), value: props.data.value}, false, props.isSelected)}
								</components.Option>
							</a>
						</Link>
						{childCategories.map((child) => {
							let childProps = {...props};
							childProps.label = child.label;
							childProps.value = child.value;
							childProps.data = child;

							return (
								<Link href={childProps.value}>
									<a className={classes.link}>
										<components.Option {...childProps} className={classes.dropdownOption}>
											{formatLabel(childProps.data, false)}
										</components.Option>
									</a>
								</Link>
							)
						})}
					</div>
				: ''}
			</>
		);
	}

	function Label({children, ...props}) {
		return (
			<span>{t('attributes:categories')}</span>
		);
	}

	function Indicator({children, ...props}) {
		let isOpen = props.selectProps.menuIsOpen;

		return (
			isOpen ? <DropdownIndicatorOpen width={12}/> : <DropdownIndicatorClosed width={12}/>
		)
	}

	function toggleChildOpen(value) {
		let newChildOpen = [...childOpen];

		if(childOpen.indexOf(value) >= 0) {
			newChildOpen.splice(childOpen.indexOf(value), 1);
		} else {
			newChildOpen.push(value);
		}

		setChildOpen(newChildOpen);
	}

	function getDefaultOptions() {
		let defaultOptions = options.filter((option) => {
			if(currentFilter && currentFilter.indexOf(option.value) >= 0) {
				return option;
			}
		});

		return defaultOptions;
	}

	return (
		options.length > 1 &&
			<>
				<div className={[classes.control, open ? 'open' : ''].join(' ')} onClick={(e) => setOpen(!open)}>
					<Label />
					{open ?
						<>
							<DropdownIndicatorOpen className={'desktop'} width={12}/>
							<DropdownIndicator className={'mobile'} width={12}/>
						</> :
						<>
							<DropdownIndicatorClosed className={'desktop'} width={12}/>
							<DropdownIndicator className={'mobile'} style={{transform: 'rotate(-90deg)'}} width={12}/>
						</>
					}
				</div>
				<Select
					hideSelectedOptions={false}
					closeMenuOnSelect={false}
					options={options}
					value={getDefaultOptions()}
					formatOptionLabel={formatLabel}
					isSearchable={false}
					controlShouldRenderValue={false}
					menuIsOpen={open}
					maxMenuHeight={10000}
					captureMenuScroll={false}
					className={className}
					components={{
						IndicatorSeparator: null,
						Placeholder: Label,
						DropdownIndicator: Indicator,
						Option: Option,
						Control: Control
					}}
					styles={{
						control: (provided, state) => {
							delete provided.borderWidth;
							delete provided.borderStyle;
							delete provided.borderColor;
							delete provided.backgroundColor;
							delete provided.cursor;
							delete provided.boxShadow;
							delete provided.minHeight;

							return {
								...provided,
							}
						},
						menu: (provided, state) => {
							delete provided.position;
							delete provided.boxShadow;

							return {
								...provided,
								marginTop: -10,
								paddingLeft: 3
							}
						},
						option: (provided, state) => {
							delete provided.color;
							delete provided.backgroundColor;
							delete provided.padding;
							delete provided[':active'];

							return {
								...provided,
								cursor: 'pointer'
							}
						},
						dropdownIndicator: (provided, state) => {
							return {
								...provided,
								color: "#666",
								'&:hover': {
									color: "#000",
								}
							}
						},
					}}
					instanceId={'select-link-' + name}
				/>
			</>
	);
}