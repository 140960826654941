import { Box, Grid } from "@material-ui/core";
import ProductItem from "./item";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "../theme";
import {useTranslation} from "tools/i18n";
import {Alert} from "@material-ui/lab";
import {useContext} from "react";
import {SessionContext} from "../providers/session";
import urlNormalizer from "tools/urlNormalization";
import {useRouter} from "next/router";
import Image from "react-storefront/Image";
import { elevateClickNotification } from "tools/elevateClient";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
	productGrid: {
		display: 'flex',
		flexWrap: 'wrap',
		gridTemplateColumns: "repeat(2, minmax(0, 50%))",
		gap: 10,
		[theme.breakpoints.up('sm')]: {
			gridTemplateColumns: "repeat(3, minmax(0, 33.333%))",
			gap: 20
		},
		[theme.breakpoints.up('md')]: {
			gridTemplateColumns: "repeat(4, minmax(0, 25%))",
			gap: 20
		}
	},
	productItem: {
		maxWidth: 'none'
	},
	productImage: {
		flexGrow: 1,
		backgroundColor: '#fff',
		'& img': {
			transition: 'opacity 0.25s ease'
		},
		'&:hover img': {
			opacity: 0.9
		}
	},
	noHitItem: {
		gridColumn: '1/5'
	},
	alertMessage: {
		textAlign: "center"
	},
	alert: {
		justifyContent: 'center'
	}
}), {name: 'RelevateProductGrid'});

function RelevateProductGrid({products, paramsHash}) {
	const router = useRouter();
	const classes = useStyles();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const {config} = useContext(SessionContext);
	const {t} = useTranslation();

	async function onClick(product) {
		await elevateClickNotification(
			product.ticket,
			config.elevateMarket,
			config.language.languageCode,
			isMobile ? 'mobile' : 'desktop'
		);
	}

	function urlNormalize(url) {
		return urlNormalizer(url, router.basePath);
	}

	function getMarketingLabel(product) {
		if(!product?.custom?.marketing_label) {
			return;
		}

		return product?.custom?.marketing_label;
	}

	function getProductImage(product) {
		let productImages = product.imageInfo?.images;

		if(!productImages || productImages.length <= 0) {
			return { src: '/images/placeholder/product.jpg', width: 550, height: 510 };
		}

		let imageSource = productImages.find((item) => {
			return item.custom.type === 'list';
		})?.sources[0] || productImages[0]?.sources[0];

		if(productImages.length > 1) {
			productImages.forEach((image) => {
				if (image['sources'][0].width === 550) {
					imageSource = image['sources'][0];
				}
			});
		}

		const width = (imageSource.width || 550);
		const height = (imageSource.height || 510);

		return {
			src: imageSource.url,
			width: width,
			height: height,
			aspectRatio: height / width,
		}
	}

	function getProductVariants(product) {
		let allVariants = product.variants.filter((variant) => variant.label).map((variant) => {
			return variant.label;
		});

		return allVariants.length > 0;
	}

	function getMainVariant(product) {
		let mainSku = product.key.split('-')[1];
		let mainVariant = product.variants.find((variant) => {
			return variant.key === mainSku;
		});

		let mainVariantInStock = mainVariant ? mainVariant.inStock : false;

		return mainVariant && mainVariantInStock ? mainVariant : product.variants.filter((variant) => variant.inStock)[0] || product.variants[0];
	}

	function getUUID(product) {
		return product.custom.uuid[0].id;
	}

	function getStock(product) {
		return product.custom?.in_stock ? parseInt(product.custom.in_stock[0].label) : 0;
	}

	function getFastDelivery(product) {
		return !!product.custom?.fast_delivery_available;
	}

	function preventFloatRoundingIssues(price, listPrice) {
		let centPrice = parseInt(price * 100);
		let centListPrice = parseInt(listPrice * 100);

		return parseInt(Math.round(100 * (centListPrice - centPrice) / centListPrice));
	}

	return (
		<Box component="div" className={[classes.productGrid, 'product-grid'].join(' ')} style={{display: 'grid'}}>
			{products.length > 0 ? products.map((product, key) => {
				let mainVariant = getMainVariant(product);
				let mainImage = getProductImage(product);

				return <ProductItem
					id={getUUID(mainVariant)}
					productNumber={mainVariant.key}
					name={product.title}
					manufacturer={product.brand}
					link={urlNormalize(product.link)}
					prices={{
						price: mainVariant.sellingPrice,
						listPrice: mainVariant.listPrice,
						isOnSale: mainVariant.sellingPrice < mainVariant.listPrice,
						discount: mainVariant.sellingPrice < mainVariant.listPrice ? {
							amount: mainVariant.sellingPrice - mainVariant.listPrice,
							percentage: preventFloatRoundingIssues(mainVariant.sellingPrice, mainVariant.listPrice)
						} : undefined
					}}
					image={<Image {...mainImage}
								  alt={product.title}
								  className={classes.productImage}
								  lazyOffset={400}
								  notFoundSrc={'/images/placeholder/product.jpg'}
								  contain/>}
					list="Search results"
					variants={getProductVariants(product) ? mainVariant.label : false}
					stock={getStock(mainVariant)}
					fastDelivery={getFastDelivery(mainVariant)}
					labels={getMarketingLabel(mainVariant)}
					available={mainVariant.inStock}
					addToCart={'minimal'}
					isOutlet={product.title.indexOf('OUTLET') >= 0}
					position={key + 1}
					clickCallback={onClick.bind(this, product)}
					elevateTicket={mainVariant.ticket}
					isMobile={isMobile}
					customClasses={{item: classes.productItem}}
					lazyHydrate={false}
					key={paramsHash + key}
				/>
			}) : <Grid xs={12} className={classes.noHitItem} item><Alert severity="warning" className={classes.alert} classes={{message: classes.alertMessage}}>{t('search-result-no-hit')}</Alert></Grid>}
		</Box>
	);
}

export default RelevateProductGrid;
