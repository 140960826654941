import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import theme from "../../../theme";
import Select from 'react-select';
import {useTranslation} from "../../../../tools/i18n";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import LazyHydrate from "../../../../tools/lazyHydrate";
import {useRouter} from "next/router";
import DropdownIndicator from "../../../../public/images/icons/drop-down.svg";

const useStyles = makeStyles({
	wrapper: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		padding: '20px 0 20px',
		[theme.breakpoints.down('md')]: {
			padding: '20px 20px 0'
		}
	},
	inner: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		width: '100%',
		maxWidth: theme.maxWidth,
		[theme.breakpoints.down('sm')]: {
			flexWrap: 'wrap'
		},
	},
	sorting: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		width: 'calc(50% - 5px)',
		marginLeft: 'auto',
		'& label': {
			marginRight: 15
		},
		[theme.breakpoints.down('md')]: {
			maxWidth: 300
		},
		[theme.breakpoints.up('md')]: {
			width: 'auto',
			flexGrow: 1,
		}
	},
	sortingDropdown: {
		display: 'flex',
		width: '100%',
		height: 'auto',
		padding: '6px 10px 6px 0px',
		cursor: 'pointer',
		border: '1px solid #666',
		[theme.breakpoints.up('lg')]: {
			height: 38,
			width: 'auto',
			minWidth: 130,
			border: 'none'
		}
	}
}, {name: 'productFilter'});

function ProductSorting({sortings, currentSorting, onSortChange, block}) {
	const classes = useStyles();
	const { t } = useTranslation(['general', 'attributes']);
	const [sorting, setSorting] = useState(getDefaultSorting());

	useEffect(() => {
		setSorting(getDefaultSorting());
	}, [sortings, currentSorting]);

	function getDefaultSorting() {
		let defaultSort = {};

		sortings.forEach((sort) => {
			if (sort.value == currentSorting) {
				defaultSort = {
					value: sort.value,
					label: sort.label
				};
			}
		});

		return defaultSort;
	}

	function sortingChanged(newSorting) {
		setSorting(newSorting);
		onSortChange(newSorting);
	}

	function Indicator({children, ...props}) {
		return (
			<DropdownIndicator width={12} height={12}/>
		)
	}

	return (
		<LazyHydrate id={"sorting" + block?.sectionId} on="visible">
			<div className={classes.wrapper}>
				<div className={classes.inner}>
					<div className={classes.sorting}>
						<Select options={sortings}
								value={sorting}
								defaultValue={getDefaultSorting()}
								onChange={sortingChanged}
								className={classes.sortingDropdown}
								isSearchable={false}
								components={{
									IndicatorSeparator: null,
									DropdownIndicator: Indicator
								}}
								styles={{
									control: (provided, state) => {
										delete provided.borderWidth;
										delete provided.borderStyle;
										delete provided.borderColor;
										delete provided.cursor;
										delete provided.minHeight;

										return {
											...provided,
											...theme.typography.dropdown,
											width: '100%',
											boxShadow: 'none',
										}
									},
									valueContainer: (provided, state) => {
										return {
											...provided,
											height: 20
										}
									},
									indicatorsContainer: (provided, state) => {
										delete provided.alignSelf;

										return {
											...provided,
											height: 12
										}
									},
									menu: (provided, state) => {
										return {
											...provided,
											zIndex: 100,
											boxShadow: theme.boxShadow.dropdownActive
										}
									},
								}}
								instanceId="sort-select"
						/>
					</div>
				</div>
			</div>
		</LazyHydrate>
	);
}

export default ProductSorting;