import React, { useContext, useEffect, useState } from 'react';
import { elevateSearch } from 'tools/elevateClient';
import { useTranslation } from 'tools/i18n';
import { Container, Typography } from '@material-ui/core';
import MetaTitle from '../../meta/title';
import { makeStyles } from '@material-ui/styles';
import ProductPagination from '../../navigation/pagination';
import ProductFilters from '../../filters/left-filters';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import theme from '../../theme';
import { SessionContext } from '../../providers/session';
import RelevateGrid from "../../products/relevateGrid";
import ProductSorting from "../../cms/blocks/product-listing/sorting";

const useStyles = makeStyles(
	() => ({
		wrapper: {
			paddingTop: 40,
			paddingBottom: 40
		},
		title: {
			textAlign: 'center',
			'& span': {
				color: '#999'
			}
		},
		subtitle: {
			marginBottom: 40,
			fontSize: 14,
			fontWeight: 300,
			textAlign: 'center'
		},
		searchPhraseAutocomplete: {
			margin: '10px 0 0',
			textAlign: 'center',
			fontStyle: 'italic',
			fontSize: 14,
			fontWeight: 300,
			color: '#333',
			'& span': {
				fontWeight: 'bold'
			}
		},
		container: {
			position: 'relative'
		},
		sortingWrapper: {

		},
		filterGridWrapper: {
			display: 'flex',
			justifyContent: 'center',
			gap: 24,
			paddingTop: 20,
			['@media only screen and (max-width: 1524px)']: {
				justifyContent: 'flex-end',
			},
			[theme.breakpoints.down('lg')]: {
				paddingLeft: 20,
				paddingRight: 20
			},
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
				paddingLeft: 0,
				paddingRight: 0
			},
			[theme.breakpoints.up('lg')]: {
				padding: '0 24px',
				'@media screen and (min-width: 1296px)': {
					'&:after': {
						content: '""',
						width: 'calc(((100% - 1200px) / 2) - 24px)',
						maxWidth: 300,
						flexShrink: 0
					}
				}
			}
		},
		gridWrapper: {
			width: '75%',
			flexGrow: 1,
			margin: 0,
			[theme.breakpoints.down('md')]: {
				width: '100%'
			},
		}
	}),
	{ name: 'SearchResults' }
);

function SearchResults({ query, router }) {
	const { t } = useTranslation(['general', 'attributes']);
	const classes = useStyles();
	const { config } = useContext(SessionContext);
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const [sortings, setSortings] = useState({ current: 'RELEVANCE', options: [] });
	const [facets, setFacets] = useState({});
	const [selectedFacets, setSelectedFacets] = useState({});

	// State
	const [productData, setProducts] = useState({ products: [], hitCount: 0 });
	let { products, hitCount } = productData;

	const [params, setParams] = useState({ ...query, initial: true });
	let { q, page, order, initial, ...filters } = params;

	// has Autocorrect
	const [autocorrect, setAutocorrect] = useState(false);

	// Secondary product list
	const [secondaryProducts, setSecondaryProducts] = useState([]);

	useEffect(() => {
		setParams({ ...query, initial: true });
	}, [query]);

	useEffect(async () => {
		let routerData = {
			pathname: router.asPath.split('?')[0],
			query: {
				...params,
			},
			shallow: true
		};

		if (routerData.query.page === 1) {
			delete routerData.query.page;
		}

		delete routerData.query.initial;

		if (!params.initial) {
			router.push(routerData);
		}
	}, [params]);

	useEffect(async () => {
		let { q, page, order, initial, ...filters } = query;

		if (q) {
			const isSoldOut = q.toLowerCase().indexOf('slutsåld') >= 0;

			const searchResults = await elevateSearch(
				q,
				order || 'RELEVANCE',
				prepareFacets(filters),
				page,
				config.elevateMarket,
				config.language.languageCode,
				isMobile ? 'mobile' : 'desktop',
				isSoldOut
			);

			// Set products
			let products = [];
			searchResults?.primaryList?.productGroups.forEach((productSuggestions) => {
				products.push(productSuggestions.products[0]);
			});

			setProducts({ products: products, hitCount: searchResults?.primaryList?.totalHits });

			// Set products from secondary list
			if(searchResults?.secondaryList?.productGroups) {
				let moreProducts = [];
				searchResults.secondaryList.productGroups.forEach((productSuggestions) => {
					moreProducts.push(productSuggestions.products[0]);
				});

				setSecondaryProducts(moreProducts);
			} else {
				setSecondaryProducts([]);
			}

			//Set sorting options
			let sortings = [];
			searchResults.primaryList.sort.options.forEach((sorting) => {
				sortings.push({
					value: sorting.id,
					label: sorting.label
				});
			});

			setSortings({current: searchResults.primaryList.sort.selected, options: sortings});

			// Set filter options
			let facets = {};
			let selectedFacets = {};
			searchResults.primaryList.facets.forEach((facet) => {
				if(facet.type === 'RANGE') {
					facets[facet.id] = {
						label: facet.label,
						type:  'range',
						min: facet.min,
						max: facet.max
					}

					if(facet.minSelected) {
						selectedFacets[facet.id] = {
							min: facet.minSelected,
							max: facet.maxSelected
						}
					}
				} else {
					let hasSelected = false;

					facets[facet.id] = {
						label: facet.label,
						key: facet.id,
						type: facet.type === 'RANGE' ? 'range' : 'dropdown',
						options: facet.values.map((option) => {
							if(option.selected) {
								hasSelected = true;
							}

							return {
								label: option.label,
								value: option.id
							}
						})
					}

					if(hasSelected) {
						selectedFacets[facet.id] = facet.values.filter((option) => option.selected).map((option) => option.id);
					}
				}
			});

			setFacets({ ...facets });
			setSelectedFacets({ ...selectedFacets });

			if(searchResults.autoCorrect) {
				setParams({...params, q: searchResults.autoCorrect.q});
				setAutocorrect(searchResults.autoCorrect.q);
			} else {
				setAutocorrect(false);
			}
		}
	}, [router]);

	function prepareFacets(facets) {
		let facetKeys = Object.keys(facets);
		let formattedFacets = {};

		facetKeys.forEach((facetKey) => {
			let facet = facets[facetKey];

			if(facet.indexOf('min:') >= 0) {
				formattedFacets[facetKey] = {
					min: parseInt(facet.split(',')[0].replace('min:', '')),
					max: parseInt(facet.split(',')[1].replace('max:', ''))
				};
			} else {
				formattedFacets[facetKey] = facet.split(',');
			}
		});

		return  formattedFacets;
	}

	function onPaginate(event, value) {
		setParams({ ...params, initial: false, page: value });
	}

	function onSortChange(data) {
		setParams({ ...params, initial: false, order: data.value });
	}

	function onFilterChange(attribute, data) {
		let newState = {
			...params,
			initial: false,
			page: 1
		};


		if (Array.isArray(data) && data.length > 0) {
			newState[attribute] = data.join(',');
		} else if(Object.keys(data).length > 0) {
			if(facets[attribute].min !== data.min || facets[attribute].max !== data.max) {
				newState[attribute] = 'min:' + data.min + ',max:' + data.max;
			} else {
				delete newState[attribute];
			}
		} else {
			delete newState[attribute];
		}

		setParams(newState);
	}

	return (
		<>
			<MetaTitle title={t('search-results-title', { query: q || '' })} />
			<div className={classes.wrapper}>
				<Typography className={classes.title} variant="h1">
					{t('search-results-title', { query: '' })}
					<span>"{q}"</span>
				</Typography>

				{autocorrect ? <Typography className={classes.searchPhraseAutocomplete} variant="body1" dangerouslySetInnerHTML={{__html: t('search-phrase-autocomplete', {originalPhrase: query.q})}} /> : ''}

				<Typography className={classes.subtitle} variant="body1">
					{hitCount > 0 ? t('search-result-hits', { hitCount }) : t('search-result-no-hit')}
				</Typography>
				<div className={classes.container}>
					<div className={classes.sortingWrapper}>
						<ProductSorting
							sortings={sortings.options}
							currentSorting={sortings.current}
							onSortChange={onSortChange}
						/>
					</div>
					<div className={classes.filterGridWrapper}>
						<ProductFilters
							currentFilters={selectedFacets}
							filters={facets}
							onFilterChange={onFilterChange}
							isOpen={false}
						/>

						<Container className={classes.gridWrapper} disableGutters>
							<RelevateGrid
								products={products}
								paramsHash={
									typeof btoa !== 'undefined'
										? btoa(JSON.stringify(params))
										: Buffer.from(JSON.stringify(params)).toString('base64')
								}
							/>

							{hitCount > 48 ? <ProductPagination
								productPerPage={48}
								currentPage={page ? parseInt(page) : 1}
								maxPage={Math.ceil(hitCount / 48) || 0}
								onChange={onPaginate}
							/> : ''}

							{secondaryProducts.length > 0 ?
								<>
									<Typography className={classes.title} style={{marginTop: 40, marginBottom: 20}} variant="h2">{t('search-related-products')}</Typography>
									<RelevateGrid
										products={secondaryProducts}
										paramsHash={
											typeof btoa !== 'undefined'
												? btoa(JSON.stringify(params))
												: Buffer.from(JSON.stringify(params)).toString('base64')
										}
									/>
								</>  : ''}
						</Container>
					</div>
				</div>
			</div>
		</>
	);
}

export default SearchResults;